import React from 'react';

import { PageSectionHeaderProps } from 'ui-kit/page-section-header/page-section-header.props';

import './page-section-header.style.scss';

const PageSectionHeader = ({ eyebrowText, title }: PageSectionHeaderProps) => {
    return (
        <div className="page-section-header">
            <h5 className="eyebrow-text">{eyebrowText}</h5>
            <h1 className="h2">{title}</h1>
            <div className="spacer" />
        </div>
    );
};

export default PageSectionHeader;
