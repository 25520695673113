import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';

import withSessionExpiration from 'hoc/withSessionExpiration';

import { getTranslatedIntraPageMenuItems, ProfileLayoutProps } from 'components/layouts/profile/profile.props';
import IntraPageMenu from 'components/navigation/intra-page-menu/intra-page-menu.component';
import PageLayout from 'components/layouts/page/page.layout';

import PageSectionHeader from 'ui-kit/page-section-header/page-section-header';

import './profile.style.scss';

const ProfileLayout = ({ children, eyebrowText, title, heading, subhead }: ProfileLayoutProps) => {
    const { t } = useTranslation();

    const imageData = useStaticQuery(graphql`
        query {
            blueSkyBackground: file(relativePath: { eq: "assets/images/feather-background.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    const intraPageMenuItems = getTranslatedIntraPageMenuItems(t);

    return (
        <PageLayout
            headerImage={imageData.blueSkyBackground}
            headerImageClassName="profile-background"
            pageContentClassName="profile-wrapper"
            metaData={{ nodeTitle: title }}
        >
            <div className="profile">
                <Container fluid="xl">
                    <Row className="justify-content-lg-center">
                        <Col xs={12} md={12} lg={8}>
                            <PageSectionHeader eyebrowText={eyebrowText} title={title} />
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col lg={2}>
                            <Row className="no-gutters">
                                <Col md={{ span: 10, offset: 1 }} lg={{ span: 12, offset: 0 }}>
                                    <IntraPageMenu
                                        menuItems={intraPageMenuItems}
                                        onClick={(path: string) => navigate(path)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={12} lg={8}>
                            <Row>
                                <Col md={{ span: 10, offset: 1 }}>
                                    {heading && (
                                        <div className="profile-active-menu-item-top-wrapper">
                                            <Container fluid>
                                                <Row>
                                                    <div className="d-flex flex-column col">
                                                        <h3 className="profile-active-menu-heading">{heading}</h3>
                                                        {subhead && (
                                                            <small className="profile-active-menu-subhead">
                                                                {subhead}
                                                            </small>
                                                        )}
                                                    </div>
                                                </Row>
                                            </Container>
                                        </div>
                                    )}
                                    <div className={`profile-active-menu-item-${heading ? 'bottom-' : ''}wrapper`}>
                                        {children}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </PageLayout>
    );
};

export default withSessionExpiration(ProfileLayout);
