import React, { useCallback } from 'react';
import classNames from 'classnames';

import { SelectOptionValue } from 'types/select';
import Link from 'ui-kit/link/link';

import NavSelect from 'ui-kit/nav-select/nav-select';
import { SelectChangeEvent } from 'ui-kit/select/select.props';

import {
    IntraPageMenuProps,
    IntraPageMenuItemProps
} from 'components/navigation/intra-page-menu/intra-page-menu.props';

import './intra-page-menu.style.scss';

const IntraPageMenu = <T extends SelectOptionValue>({ className, menuItems, onClick }: IntraPageMenuProps<T>) => {
    const classes = classNames('', className);

    const handleNavSelectChange = ({ option: { value } }: SelectChangeEvent<T>) => {
        const { pathname } = typeof window !== 'undefined' ? window && window.location : { pathname: null };
        if (onClick && pathname !== value.toString()) {
            onClick(value.toString());
        }
    };

    const getDefaultValue = useCallback(() => {
        const { pathname } = typeof window !== 'undefined' ? window && window.location : { pathname: null };
        return menuItems.find((opt) => pathname === opt.value) || menuItems[0];
    }, [typeof window !== 'undefined' ? window.location : null]);
    const defaultValue = getDefaultValue();

    return (
        <div className={classes}>
            <NavSelect
                className="d-lg-none"
                options={menuItems}
                onChange={handleNavSelectChange}
                defaultValue={defaultValue}
            />
            <div className="intra-page-menu d-none d-lg-flex" data-ga-location="LeftNav">
                {menuItems &&
                    menuItems.map(
                        (item: IntraPageMenuItemProps<T>) =>
                            !item.hidden && (
                                <Link
                                    key={`site-intra-page-${item.label}`}
                                    to={item.value.toString()}
                                    label={item.label}
                                    variant={'nav-sidebar'}
                                    dataGALocation={'LeftNav'}
                                />
                            )
                    )}
            </div>
        </div>
    );
};

export default IntraPageMenu;
