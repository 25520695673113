import React from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { SecureChangePassword } from 'types/change-password';
import SecureChangePasswordForm from './intra-page-items/password-change.item';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { accountWebProfileSelector } from 'state/account/account.selectors';
import { accountFetchWebProfileRoutine, accountUpdateSecurePasswordRoutine } from 'state/account/account.routines';

const Security = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const webProfile = useSelector(accountWebProfileSelector);

    if (webProfile === undefined) {
        dispatch(accountFetchWebProfileRoutine.trigger());
    }
    const username = webProfile?.UserName;

    const changePasswordModalContent = (
        <BirdiModalContent
            icon={'success'}
            title={t('pages.profile.security.success')}
            body={t('pages.profile.security.confirmationMessage')}
        />
    );

    const changePasswordFailureModalContent = (data: string) => (
        <BirdiModalContent icon={'alert'} title={t('pages.profile.security.errors.error')} body={data} />
    );

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.security.title`)}
            heading={t(`pages.profile.security.heading`)}
        >
            <SecureChangePasswordForm
                onSubmit={(changedPasswords: SecureChangePassword) => {
                    dispatch(
                        accountUpdateSecurePasswordRoutine.trigger({
                            username,
                            currentPassword: changedPasswords.currentPassword,
                            newPassword: changedPasswords.newPassword,
                            onSuccess: () =>
                                dispatch(
                                    openModal({
                                        showClose: true,
                                        bodyContent: changePasswordModalContent,
                                        ctas: [
                                            {
                                                label: t('pages.profile.security.gotIt'),
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                }
                                            }
                                        ]
                                    })
                                ),
                            onFailure: (data: string) => {
                                dispatch(
                                    openModal({
                                        showClose: true,
                                        bodyContent: changePasswordFailureModalContent(data),
                                        ctas: [
                                            {
                                                label: t('pages.profile.security.gotIt'),
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                }
                                            }
                                        ]
                                    })
                                );
                            }
                        })
                    );
                }}
            />
        </ProfileLayout>
    );
};

export default Security;
